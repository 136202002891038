import { Document } from './Document';
import { Phases } from './Phases';
import { Score } from './Score';
import Statistics from './Statistics';

enum EventRating {
  BAD = 'BAD',
  FATAL = 'FATAL',
  GOOD = 'GOOD',
  GREAT = 'GREAT',
  NORMAL = 'NORMAL',
}

enum EventType {
  CUSTOM = 'CUSTOM',
  FEELING = 'FEELING',
  MEAL = 'MEAL',
  REST = 'REST',
  TRAINING = 'TRAINING',
}

enum FinalAnalysisEventType {
  TRAINING = 'TRAINING',
  MEAL = 'MEAL',
  REST = 'REST',
  OTHER = 'OTHER',
}

enum EventSubType {
  TRAINING_LOW_INTENSITY_CARDIO = 'TRAINING_LOW_INTENSITY_CARDIO',
  TRAINING_HIIT = 'TRAINING_HIIT',
  TRAINING_STRENGTH = 'TRAINING_STRENGTH',
  TRAINING_ENDURANCE = 'TRAINING_ENDURANCE',
  FOOTBALL = 'FOOTBALL',
  PADDLE_TENNIS = 'PADDLE_TENNIS',
  GYMNASTICS = 'GYMNASTICS',
  POWERLIFTING = 'POWERLIFTING',
  WALKING = 'WALKING',
  WEIGHTLIFTING = 'WEIGHTLIFTING',
  YOGA = 'YOGA',
  BASKETBALL = 'BASKETBALL',
  BOXING = 'BOXING',
  CYCLING = 'CYCLING',
  FUNCTIONAL_FITNESS = 'FUNCTIONAL_FITNESS',
  MOUNTAIN_BIKING = 'MOUNTAIN_BIKING',
  RUNNING = 'RUNNING',
  SOCCER = 'SOCCER',
  SURFING = 'SURFING',
  SWIMMING = 'SWIMMING',
  TENNIS = 'TENNIS',
  TRIATHLON = 'TRIATHLON',
  SEX = 'SEX',
  SPINNING = 'SPINNING',
  AEROBICS = 'AEROBICS',
  BADMINTON = 'BADMINTON',
  CROSSFIT = 'CROSSFIT',
  HANDBALL = 'HANDBALL',
  INDOOR_CARDIO = 'INDOOR_CARDIO',
  INDOOR_CLIMBING = 'INDOOR_CLIMBING',
  INDOOR_RUNNING = 'INDOOR_RUNNING',
  KICKBOXING = 'KICKBOXING',
  ROLLER_SKATING = 'ROLLER_SKATING',
  ZUMBA = 'ZUMBA',
  CLIMBING = 'CLIMBING',
  COOLDOWN = 'COOLDOWN',
  OTHER = 'OTHER',
}

interface EventBase {
  comment: string;
  date: string;
  deleted: boolean;
  endDate?: string;
  id: string;
  name: string;
  rating: EventRating;
  subType?: EventSubType;
  type: EventType;
  calories: number;
  distance: number;
  minHeartRate: number;
  maxHeartRate: number;
  avgHeartRate: number;
  quality: number;
}

interface Event extends EventBase {
  creationDate: string;
  modificationDate: string;
  patientId: string;
}

interface EventDto extends EventBase {
  documents: Array<Document>;
  eventPhases: Array<Phases>;
}

interface EventScoreDto extends EventBase {
  score?: Score;
}

interface EventStatisticsDto extends EventBase {
  statistics: Statistics;
  startDateChart: string;
  endDateChart: string;
}

export {
  EventBase,
  Event,
  EventDto,
  EventScoreDto,
  EventStatisticsDto,
  EventRating,
  EventType,
  EventSubType,
  FinalAnalysisEventType,
};
