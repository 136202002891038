import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_GetRequestError = _resolveComponent("GetRequestError")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_EventsTable = _resolveComponent("EventsTable")!

  return (_ctx.isLoadingEvents)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_ctx.isDataLoadedErrorful)
      ? (_openBlock(), _createBlock(_component_GetRequestError, { key: 1 }))
      : (_ctx.isMedicalTestCanceled && _ctx.isEventListEmpty)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 2,
            heading: _ctx.$t('medicalTest.no-data-title'),
            text: _ctx.$t('medicalTest.canceled-test-no-data-message'),
            icon: "icon-window_close"
          }, null, 8, ["heading", "text"]))
        : (_ctx.ismedicalTestStateStarted)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 3,
              heading: _ctx.$t('cgmDetail.not-started-title'),
              icon: "icon-loading",
              text: _ctx.$t('cgmDetail.not-started-message'),
              textAtBottom: true
            }, null, 8, ["heading", "text"]))
          : (_ctx.isEventListEmpty)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 4,
                heading: _ctx.$t('events.no-data-title'),
                icon: "icon-alarm",
                text: _ctx.$t('events.no-data-message'),
                textAtBottom: true
              }, null, 8, ["heading", "text"]))
            : (_openBlock(), _createBlock(_component_EventsTable, {
                key: 5,
                events: _ctx.events
              }, null, 8, ["events"]))
}