import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "single-chart" }
const _hoisted_2 = ["stroke-dasharray"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      viewBox: "0 0 36 36",
      class: _normalizeClass(["circular-chart", _ctx.color])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("path", {
        class: "circle-bg",
        d: "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n         a 15.9155 15.9155 0 0 1 0 -31.831"
      }, null, -1)),
      _createElementVNode("path", {
        class: _normalizeClass(["circle", { 'circle--animated': _ctx.animate }]),
        "stroke-dasharray": `${_ctx.percentage}, 100`,
        d: "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
      }, null, 10, _hoisted_2),
      _createElementVNode("text", {
        x: "18",
        y: "23.5",
        class: _normalizeClass(["value", { empty: _ctx.emptyState }])
      }, _toDisplayString(_ctx.value), 3)
    ], 2))
  ]))
}