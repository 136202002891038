import { EventDto, EventScoreDto, EventStatisticsDto } from '@/models/Event';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useProfileStore } from '@/store/profile.module';
import { ServiceError } from './util/ServiceError';
import handleError from './util/ErrorHandler';

const storeProfile = useProfileStore();

class EventService {
  axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async findAllFiltered(
    medicalTestId: string,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined,
    scores: boolean | undefined = true,
  ) {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];
    if (startDate) {
      params.push(['startDate', startDate]);
    }
    if (endDate) {
      params.push(['endDate', endDate]);
    }
    params.push(['scores', String(scores)]);
    params.push(['medicalTestId', medicalTestId]);
    const searchParams = new URLSearchParams(params);

    try {
      const networkResponse = await this.axiosInstance.get<EventScoreDto[]>(
        `${process.env.VUE_APP_API_EVENT_SERVICE}/event?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request find all filtered with medical test id '${medicalTestId}'`);
      return undefined;
    }
  }

  async find(eventId: string, patientId: string): Promise<EventDto | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId: patientId,
      },
    };
    try {
      const networkResponse = await this.axiosInstance.get<EventDto>(
        `${process.env.VUE_APP_API_EVENT_SERVICE}/event/${eventId}?accountId=${storeProfile.accountId}`,
        options,
      );

      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request find with id '${eventId}'`);
      return handleError(error);
    }
  }

  async findStatistics(eventId: string, patientId: string): Promise<EventStatisticsDto | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId: patientId,
      },
    };
    try {
      const networkResponse = await this.axiosInstance.get<EventStatisticsDto>(
        `${process.env.VUE_APP_API_EVENT_SERVICE}/event/${eventId}/statistics?accountId=${storeProfile.accountId}`,
        options,
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request find statistics with eventId '${eventId}'`);
      return handleError(error);
    }
  }

  async findByMedicalTestAndPatientId(
    medicalTestId: string,
    patientId: string,
    scores: boolean,
    startDate?: string,
    endDate?: string,
  ) {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    if (scores) {
      params.push(['scores', 'true']);
    }
    if (startDate) {
      params.push(['startDate', startDate]);
    }
    if (endDate) {
      params.push(['endDate', endDate]);
    }
    params.push(['patientId', String(patientId)]);
    params.push(['medicalTestId', medicalTestId]);
    const searchParams = new URLSearchParams(params);

    try {
      const networkResponse = await this.axiosInstance.get<EventScoreDto[]>(
        `${process.env.VUE_APP_API_EVENT_SERVICE}/event?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request find all filtered with medical test id '${medicalTestId}'`);
      return handleError(error);
    }
  }

  async findAll(patientId: string, startDate: string, endDate: string): Promise<EventScoreDto[] | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['patientId', patientId]);
    params.push(['startDate', startDate]);
    params.push(['endDate', endDate]);

    const searchParams = new URLSearchParams(params);

    try {
      const networkResponse = await this.axiosInstance.get<EventScoreDto[]>(
        `${process.env.VUE_APP_API_EVENT_SERVICE}/event/activity?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request find all filtered with patient id '${patientId}'`);
      return handleError(error);
    }
  }

  async findSleepEventByEndDate(
    patientId: string,
    endDateStart: string,
    endDateEnd: string,
  ): Promise<EventDto[] | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['patientId', patientId]);
    params.push(['endDateEnd', endDateEnd]);
    params.push(['endDateStart', endDateStart]);

    const searchParams = new URLSearchParams(params);

    try {
      const networkResponse = await this.axiosInstance.get<EventDto[]>(
        `${process.env.VUE_APP_API_EVENT_SERVICE}/event/sleep?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error: any) {
      console.error(error.response);
      console.debug(`>[EventService] Error: request find sleep Event filtered with patient id '${patientId}'`);
      return handleError(error);
    }
  }
}

export const eventService = new EventService();
