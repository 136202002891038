enum ScoreTimeFrame {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  EVENT = 'EVENT',
}

enum ScoreType {
  // Common
  MIN_VALUE = 'MIN_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  AVG_VALUE = 'AVG_VALUE',
  TOTAL_VALUE = 'TOTAL_VALUE',

  AVG_GLUCOSE = 'AVG_GLUCOSE',
  COEFFICIENT_OF_VARIATION = 'COEFFICIENT_OF_VARIATION',
  COMPARISON_PREVIOUS_DAY = 'COMPARISON_PREVIOUS_DAY',
  EFFICIENCY = 'EFFICIENCY',
  FASTING_GLUCOSE = 'FASTING_GLUCOSE',
  FAT_OXIDATION_ACTIVITY = 'FAT_OXIDATION_ACTIVITY',
  FAT_OXIDATION_RESTING = 'FAT_OXIDATION_RESTING',
  MAX_GLUCOSE = 'MAX_GLUCOSE',
  MIN_GLUCOSE = 'MIN_GLUCOSE',
  POST_PRANDIAL = 'POST_PRANDIAL',
  TIME_IN_RANGE = 'TIME_IN_RANGE',
  TOTAL_ENERGY_ACTIVITY = 'TOTAL_ENERGY_ACTIVITY',
  TOTAL_ENERGY_RESTING = 'TOTAL_ENERGY_RESTING',
}

interface Score {
  type: ScoreType;
  value?: number;
  optimalValue: number;
  optimalValueMin?: number;
  optimalValueMax?: number;
}

export { Score, ScoreType, ScoreTimeFrame };
