
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    score: { type: Number },
    color: { type: String, required: true },
    animate: { type: Boolean, default: false },
  },
  setup(props) {
    const percentage = computed(() => (props.score ? props.score * 10 : 0));
    const value = computed(() => (props.score ? props.score.toFixed(1) : '-'));
    const emptyState = computed(() => (props.score ? false : true));
    return { percentage, value, emptyState };
  },
});
