
import EventsTable from '@/components/cgm/EventsTable.vue';
import GetRequestError from '@/components/common/GetRequestError.vue';
import EmptyState from '@/components/EmptyState.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { MedicalTest, MedicalTestState } from '@/models/MedicalTest';
import router from '@/router';
import { eventService } from '@/services/EventService';
import { ServiceError } from '@/services/util/ServiceError';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import moment from 'moment';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    EventsTable,
    LoadingComponent,
    EmptyState,
    GetRequestError,
  },
  setup() {
    const route = useRoute();
    const storeCGM = useCgmDetailStore();
    const patientId = route.params.patientId as string;
    const medicalTestId = route.params.medicalTest as string;
    const isLoadingEvents = ref(true);
    const isDataLoadedErrorful = ref(false);
    const isEventListEmpty = computed(() => events.value && events.value.length === 0);
    const filters = ref([]);
    const showPaginator = computed(() => events.value && events.value.length > 10);
    const events = ref<any[]>([]);
    const medicalTest = computed(() => storeCGM.getMedicalTestSelected as MedicalTest);
    const isMedicalTestCanceled = computed(
      () => medicalTest.value && medicalTest.value.state === MedicalTestState.CANCELED,
    );
    const ismedicalTestStateStarted = computed(
      () => !medicalTest.value || medicalTest.value?.state === MedicalTestState.STARTED,
    );

    const finishDate = computed(() => {
      if (medicalTest.value.finishDate) {
        return medicalTest.value.finishDate;
      }

      const timezone = storeCGM.getMedicalTestSelectedUserTimezone;
      const patientDate = dateFormat.utcToTimezone(medicalTest.value.startDate, timezone);
      const finishDate = moment(patientDate).add(14, 'days');

      return finishDate.format('YYYY-MM-DDTHH:mm:ss');
    });

    onMounted(async () => {
      isLoadingEvents.value = true;

      const theEvents = await eventService.findByMedicalTestAndPatientId(
        medicalTestId,
        patientId,
        true,
        medicalTest.value.startDate,
        finishDate.value,
      );
      if (theEvents instanceof ServiceError) {
        isDataLoadedErrorful.value = true;
      } else {
        events.value = theEvents || [];
      }
      isLoadingEvents.value = false;
    });
    return {
      medicalTestId,
      patientId,
      isLoadingEvents,
      isEventListEmpty,
      filters,
      events,
      showPaginator,
      dateFormat,
      isDataLoadedErrorful,
      router,
      isMedicalTestCanceled,
      ismedicalTestStateStarted,
    };
  },
});
