import { Score, ScoreType } from '@/models/Score';

const scoreHelper = {
  scoreColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value >= 8) return 'green';
    if (value < 5) return 'red';
    return 'orange';
  },

  glucoseAvgColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value >= 81 && value <= 106) return 'green';
    if (value <= 60 || value >= 136) return 'red';
    return 'orange';
  },

  glucoseMinColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value >= 65) return 'green';
    if (value <= 45) return 'red';
    return 'orange';
  },

  glucoseMaxColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value <= 140) return 'green';
    if (value >= 350) return 'red';
    return 'orange';
  },

  timeInRangeColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value >= 85) return 'green';
    if (value <= 70) return 'red';
    return 'orange';
  },

  coefficientOfVariationColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value <= 10) return 'green';
    if (value >= 19) return 'red';
    return 'orange';
  },

  fastingGlucosegColor(value: number | undefined): string {
    if (value == null) return 'grey';
    if (value >= 66 && value <= 95) return 'green';
    if (value <= 50 || value >= 121) return 'red';
    return 'orange';
  },

  measurementColor(score: Score) {
    switch (score.type) {
      case ScoreType.MIN_GLUCOSE:
        return scoreHelper.glucoseMinColor(score.value);
      case ScoreType.MAX_GLUCOSE:
        return scoreHelper.glucoseMaxColor(score.value);
      case ScoreType.AVG_GLUCOSE:
        return scoreHelper.glucoseAvgColor(score.value);
      case ScoreType.COEFFICIENT_OF_VARIATION:
        return scoreHelper.coefficientOfVariationColor(score.value);
      case ScoreType.TIME_IN_RANGE:
        return scoreHelper.timeInRangeColor(score.value);
      case ScoreType.FASTING_GLUCOSE:
        return scoreHelper.fastingGlucosegColor(score.value);
      default:
        return scoreHelper.glucoseAvgColor(score.value);
    }
  },

  measurementUnit(score: Score) {
    if (score.value == null) {
      return '';
    }
    switch (score.type) {
      case ScoreType.COEFFICIENT_OF_VARIATION:
      case ScoreType.TIME_IN_RANGE:
        return '%';
      default:
        return 'mg';
    }
  },
};

export default scoreHelper;
