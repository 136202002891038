import { MedicalTest } from '@/models/MedicalTest';
import { defineStore } from 'pinia';

export const useCgmDetailStore = defineStore('cgm-detail-store', {
  state: () => ({
    medicalTestSelected: undefined as MedicalTest | undefined,
    medicalTestSelectedView: '',
    medicalTestSelectedDates: {
      startDate: '',
      endDate: '',
    },
    medicalTestSelectedUserTimezone: '',
  }),
  getters: {
    getMedicalTestSelected: (applicationState: any) => applicationState.medicalTestSelected,
    getmedicalTestSelectedView: (applicationState: any) => applicationState.medicalTestSelectedView,
    getmedicalTestSelectedDates: (applicationState: any) => applicationState.medicalTestSelectedDates,
    getMedicalTestSelectedUserTimezone: (applicationState: any) => applicationState.medicalTestSelectedUserTimezone,
    getMedicalTestSelectedStartDate: (applicationState: any) =>
      applicationState.medicalTestSelectedDates.startDate ? applicationState.medicalTestSelectedDates.startDate : '',
    getMedicalTestSelectedEndDate: (applicationState: any) =>
      applicationState.medicalTestSelectedDates.endDate ? applicationState.medicalTestSelectedDates.endDate : '',
  },
  actions: {
    setMedicalTestSelected(medicalTest: MedicalTest) {
      this.medicalTestSelected = medicalTest;
    },

    resetMedicalTestSelected() {
      this.medicalTestSelected = undefined;
    },

    setMedicalTestSelectedView(view: string) {
      this.medicalTestSelectedView = view;
    },

    resetMedicalTestSelectedView() {
      this.medicalTestSelectedView = '';
    },

    setMedicalTestSelectedDates(payload: { startDate: string; endDate: string | null }) {
      this.medicalTestSelectedDates = {
        startDate: payload.startDate || '',
        endDate: payload.endDate || '',
      };
    },

    resetMedicalTestSelectedDates() {
      this.medicalTestSelectedDates = {
        startDate: '',
        endDate: '',
      };
    },

    setMedicalTestSelectedUserTimezone(view: string) {
      this.medicalTestSelectedUserTimezone = view;
    },

    resetMedicalTestSelectedUserTimezone() {
      this.medicalTestSelectedUserTimezone = '';
    },

    resetMedicalTestSelectedAll() {
      this.resetMedicalTestSelected();
      this.resetMedicalTestSelectedView();
      this.resetMedicalTestSelectedDates();
      this.resetMedicalTestSelectedUserTimezone();
    },
  },
});
